.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-width: 375px;
    margin: 0 auto;
  }
  
  h1{
    color: purple;
    font-size: medium;
    font-weight: bold;
    padding-bottom: 2em;
  }
  
  ol {
    list-style-type: none;
    padding: 0;
  }
  
  li{
    text-align: center;
  }
  
  b {
    margin-right: 0.5em;
  }
  
  .button-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .logo-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: purple;
    cursor: pointer;
    text-align: center;
    font-size: medium;
    color: white;
    border-radius: 10px;
    width: 50%;
  }
  
  .logo-button img, .logo-button svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .logo-button span {
    font-size: 13px;
    display: inline;
  }
  
  .text-field-container {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 3em;
    min-width: 350px;
  }
  
  .field-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .text-field {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
  }